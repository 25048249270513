import AggregateUserInfor from 'components/AggregateUserInfor/AggregateUserInfor';
import { Table } from 'react-bootstrap';
import ClaimRewards from 'components/modal/ClaimRewards';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getPrice } from '../../utils/getPrice';
import { ReactComponent as ArrowUpRight } from '../../assets/icon/share.svg';
import ClaimRewardsSuccess from 'components/modal/ClaimRewardsSuccess';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import info_faucet_tooltip from '../../assets/icon/info_faucet_tooltip.svg';
import { ChainId, SCAN_URL, getTargetChainId } from '../../config';
import { updateAbcAddress } from '../../services/services';
import { useSelector } from 'react-redux';

const listReward = [
  {
    date: 'Dec 22, 2023',
    type: 'Trading',
    item: 'Crypto Pig #2023',
    reward: '4.3 TALK',
    chain: 'Polygon',
    txHash: '0x123456780444444441234',
  },
  {
    date: 'Dec 22, 2023',
    type: 'Invite',
    item: 'vorigar',
    reward: '5 TALK',
    chain: '',
    txHash: '',
  },
  {
    date: 'Dec 22, 2023',
    type: 'Event',
    item: 'Event name',
    reward: '25 TALK',
    chain: '',
    txHash: '',
  },
];
const isProduction = process.env.REACT_APP_PHASE === 'production';
const Rewards = () => {
  const [isOpenClaim, setIsOpenClaim] = useState(false);
  const [isOpenClaimSuccess, setIsOpenClaimSuccess] = useState(false);
  const [clickedBox, setClickedBox] = useState(0);
  const [totalRewards, setTotalRewards] = useState<any>();
  const [refererRewards, setRefererRewards] = useState<any>();
  const [specialRewards, setSpecialRewards] = useState<any>();
  const [tradingRewards, setTradingRewards] = useState<any>();
  const [claimRewards, setClaimRewards] = useState<any>();
  const [userPoint, setUserPoint] = useState(0);
  const [pointHistories, setPointHistories] = useState<any>();
  const user = useSelector((state: any) => state.user.user);
  const uid = user.uid;
  const isLogin = uid !== '';
  // const TitleTotal = (selected) => {
  //   switch (selected) {
  //     case 0:
  //       return 'Total Rewards';
  //     case 1:
  //       return 'Total trading rewards';
  //     case 2:
  //       return 'Total # of invitees';
  //     case 3:
  //       return 'Total claimed rewards';
  //     default:
  //       return '';
  //   }
  // };
  // const ContentTotal = (selected) => {
  //   switch (selected) {
  //     case 0:
  //       return "Total rewards is the total count of rewards you've earned to date.";
  //     case 1:
  //       return 'Total trading rewards are the total amount of rewards you have earned by trading items.';
  //     case 2:
  //       return 'Total # of invitees is the total number of friends you invited.';
  //     case 3:
  //       return 'Total claimed rewards is the total number of rewards you have claimed so far.';
  //     default:
  //       return '';
  //   }
  // };

  const getMyRewards = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/reward/getMyRewards`, {
      withCredentials: true,
    });
    // console.log('res:::::1', res);
    const totalRewards = res.data?.data?.pointAll?.length > 0 ? res.data?.data?.pointAll[0] : undefined;
    const refererRewards = res.data?.data?.pointReferer?.length > 0 ? res.data?.data?.pointReferer[0] : undefined;
    const specialRewards = res.data?.data?.pointSpecial?.length > 0 ? res.data?.data?.pointSpecial[0] : undefined;
    const tradingRewards = res.data?.data?.pointTrading?.length > 0 ? res.data?.data?.pointTrading[0] : undefined;
    const claimRewards = res.data?.data?.userClaim?.length > 0 ? res.data?.data?.userClaim[0] : undefined;
    const userPoint = res.data?.data?.userPoint || 0;
    setTotalRewards(totalRewards);
    setTradingRewards(tradingRewards);
    setRefererRewards(refererRewards);
    setSpecialRewards(specialRewards);
    setClaimRewards(claimRewards);
    setUserPoint(userPoint);
  };

  const getMyRewardsHistory = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/reward/getMyRewardsHistory`, {
      withCredentials: true,
    });
    // console.log('res:::::2', res);
    const pointHistories = res.data?.data?.pointHistories;
    setPointHistories(pointHistories);
  };

  const getTargetName = (type: string) => {
    switch (type) {
      case 'USER_CLAIM':
        return 'User Claim';
      case 'BUY_REWARD':
        return 'Buy Reward';
      case 'SELL_REWARD':
        return 'Sell Reward';
      case 'TRADE_BONUS_REWARD':
        return 'Trade event Reward';
      case 'ROULETTE_REWARD':
        return 'Roulette Reward';
      case 'REFERER_REWARD':
        return 'Referral Reward';
      default:
        return 'Reward';
    }
  };
  let isLoaded = false;
  useEffect(() => {
    if (!isLoaded) {
      isLoaded = true;
      const task = async () => {
        const abcAddress = JSON.parse(window.localStorage.getItem('abcUser'))?.accounts[0]?.ethAddress;
        if (abcAddress) await updateAbcAddress(abcAddress);
        getMyRewards();
        getMyRewardsHistory();
      };
      task();
    }
  }, []);

  useEffect(() => {
    if (!isLogin) location.replace('/accounts?redirectUrl=/my-items');
  }, []);

  const tooltipContent = `
    <ul class="color-92929f mt-0 p-l-16">
      <li>Base Talken Market fee is 2% (OpenSea 2.5%)</li>
      <li>For buyers, 1.0% of the purchase amount will be converted to TALK and credited as reward points (0.5% ongoing + 0.5% event)</li>
      <li>For sellers, 1.0% of the seller's fee will be converted to TALK and credited as reward points (0.5% ongoing + 0.5% event)</li>
    </ul>
  `;

  const tooltipReferral = `
    <ul class="color-92929f mt-0 p-l-16">
      <li>For inviters, 0.5% of the sales amount will be converted to TALK and credited as reward points (ongoing)</li>
    </ul>
  `;

  const tooltipClaimable = `
  <ul class="color-92929f mt-0 p-l-16">
    <li>"Claimable rewards" are aggregated based on the wallet address.</li>
    <li>The other items, such as quantities for claimed rewards, each activity, and rewards history, are displayed based on the User ID.</li>
  </ul>
`;

  return (
    <div className="rewards min-height-content">
      {!isMobile && <AggregateUserInfor />}
      <div className="content-rewards">
        <div className="title-rewards mb-4">Rewards</div>
        {/* {isMobile && <AggregateUserInfor />} */}
        <div className="wrapper-content-rewards-top">
          <ul className="color-92929f d-flex flex-column fs-12 fw-4 gap-4px mt-0 p-l-16">
            <li>Base Talken Market fee is 2% (OpenSea 2.5%)</li>
            <li>
              For inviters, 0.5% of the sales amount will be converted to TALK and credited as reward points (ongoing)
            </li>
            <li>
              For buyers, 1.0% of the purchase amount will be converted to TALK and credited as reward points (0.5%
              ongoing + 0.5% event)
            </li>
            <li>
              For sellers, 1.0% of the seller's fee will be converted to TALK and credited as reward points (0.5%
              ongoing + 0.5% event)
            </li>
          </ul>
        </div>
        <div className="wrapper-content-rewards">
          <div className="content-rewards d-grid grid-col-4 grid-col-xs-2 w-100 gap-14px gap-xs-11px">
            <div
              className={`box-reward w-100 ${clickedBox == 0 ? 'box-reward-clicked' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                setClickedBox(0);
              }}
            >
              <div className={`title-box ${clickedBox == 0 ? 'title-box-clicked' : ''}`}>Total Rewards</div>
              <div className="value-box">{`${totalRewards ? getPrice(totalRewards.pointSum, '') : 0} TALK`}</div>
            </div>
            <div
              className={`box-reward w-100 ${clickedBox == 1 ? 'box-reward-clicked' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                setClickedBox(1);
              }}
            >
              <div className={`d-flex gap-4px title-box ${clickedBox == 1 ? 'title-box-clicked' : ''}`}>
                Trading rewards{' '}
                <img
                  data-class="my-tooltip"
                  data-multiline={true}
                  className="button h-16px w-16px"
                  src={info_faucet_tooltip}
                  alt=""
                  // data-tip={`The Gas fee covers the source and destination blockchains transaction fees paid by Layer Zero. <br>\n Source Gas fee: "0.12345", <br>\n Destination Gas fee: $0.12345`}
                  data-tip={tooltipContent}
                  data-for="helpTooltip"
                  max-width="50"
                />
              </div>
              <ReactTooltip id="helpTooltip" place="bottom" type="dark" effect="solid" html={true} />
              <div className="value-box">
                {`${tradingRewards ? getPrice(tradingRewards?.pointSum, '') : 0} TALK / 
                ${tradingRewards ? tradingRewards?.usedCount : 0} trades`}
              </div>
            </div>
            <div
              className={`box-reward w-100 ${clickedBox == 2 ? 'box-reward-clicked' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                setClickedBox(2);
              }}
            >
              <div className={`d-flex gap-4px title-box ${clickedBox == 2 ? 'title-box-clicked' : ''}`}>
                Referral rewards
                <img
                  data-class="my-tooltip"
                  data-multiline={true}
                  className="button h-16px w-16px"
                  src={info_faucet_tooltip}
                  alt=""
                  // data-tip={`The Gas fee covers the source and destination blockchains transaction fees paid by Layer Zero. <br>\n Source Gas fee: "0.12345", <br>\n Destination Gas fee: $0.12345`}
                  data-tip={tooltipReferral}
                  data-for="helpTooltip"
                  max-width="50"
                />
              </div>
              <ReactTooltip id="helpTooltip" place="bottom" type="dark" effect="solid" html={true} />
              <div className="value-box">{`${refererRewards ? getPrice(refererRewards?.pointSum, '') : 0} TALK / 
                ${refererRewards ? refererRewards?.usedCount : 0} invitees`}</div>
            </div>
            <div
              className={`box-reward w-100 ${clickedBox == 3 ? 'box-reward-clicked' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                setClickedBox(3);
              }}
            >
              <div className={`title-box ${clickedBox == 3 ? 'title-box-clicked' : ''}`}>Special rewards</div>
              <div className="value-box">{`${specialRewards ? getPrice(specialRewards?.pointSum, '') : 0} TALK`}</div>
            </div>
          </div>
          <div className="line-reward" />
          <div className="content-rewards flex-between p-l-16 p-r-16 p-l-xs-8 p-r-xs-8 w-100 gap-8px">
            <div className="d-grid fs-14 fw-7 gap-8px">
              <div className="d-flex gap-4px align-item-center fs-xs-12 color-reward">
                Claimable / Claimed Rewards
                <img
                  data-class="my-tooltip"
                  data-multiline={true}
                  className="button h-16px w-16px"
                  src={info_faucet_tooltip}
                  alt=""
                  data-tip={tooltipClaimable}
                  data-for="helpTooltip"
                  max-width="50"
                />
              </div>
              <ReactTooltip id="helpTooltip" place="bottom" type="dark" effect="solid" html={true} />
              <div className="fs-xs-16 fw-8">
                {`${userPoint ? getPrice(userPoint, '') : 0} TALK / 
                ${claimRewards ? getPrice(-1 * claimRewards?.pointSum, '') : 0} TALK`}
              </div>
            </div>
            <button
              className="button button-edit h-auto hover-opa-7"
              onClick={(e) => {
                e.stopPropagation();
                const totalTalk = userPoint ? Number(getPrice(userPoint, '')) : 0;
                if (totalTalk < 10) return alert('Claim is possible for 10 TALK or more.');
                setIsOpenClaim(true);
              }}
            >
              Claim
            </button>
          </div>
          <div className="line-reward" />
          <div className="content-rewards d-grid p-l-16 p-r-16 p-l-xs-8 p-r-xs-8 w-100 gap-8px">
            <div className="fs-14 fw-8">Rewards history</div>
            <div className="d-flex d-xs-grid fs-14 fs-xs-12 fw-5 gap-xs-16px justify-content-space-between">
              <div className="color-reward">In here, you can check the most recent 10 entries of reward records.</div>
              <div className="fs-12 color-reward">{`Recent ${pointHistories?.length} entries`}</div>
            </div>
          </div>
          <div className="line-reward" />
          <div className="content-rewards p-l-16 p-r-16 p-l-xs-8 p-r-xs-8 w-100">
            <div className="horizontal-scroll">
              <Table className="fs-xs-12">
                <thead>
                  <tr>
                    <th className="color-reward">Date</th>
                    <th className="color-reward">Rewards</th>
                    <th className="color-reward">Type</th>
                    <th className="color-reward w-236px w-xs-146px">Item / Nickname</th>
                    <th className="color-reward">Tx Hash</th>
                  </tr>
                </thead>
                {pointHistories?.length > 0 && (
                  <tbody>
                    {pointHistories?.map((item, i) => (
                      <tr key={i}>
                        <td className="">{moment(item.updatedAt).fromNow()}</td>
                        <td className="fw-7">{item.point ? getPrice(item.point, 'TALK') : '0 TALK'}</td>
                        <td>{getTargetName(item.type)}</td>
                        <td className="fw-7 w-236px w-xs-146px d-block">{item.nftInfo2?.name || item?.reason}</td>
                        <td>
                          <div
                            className="flex-start gap-10px hover-cusor-pointer hover-opa-7"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(
                                `${SCAN_URL[getTargetChainId(item.nftInfo2?.chain)]}tx/${item.txHash2}`,
                                '_blank'
                              );
                            }}
                          >
                            <div className="">{item?.txHash2 ? item?.txHash2?.slice(0, 11) + '...' : ''}</div>
                            {item?.txHash2 && item.txHash2 != undefined && (
                              <ArrowUpRight className="path-black dark:path-white" />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              {pointHistories?.length <= 0 && (
                <div className="fs-14 fs-xs-12 opacity-7 text-base text-center p-t-40 p-b-56 p-t-xs-20 p-b-xs-32">
                  “There is no data.”
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ClaimRewards
        show={isOpenClaim}
        claimableAmount={userPoint}
        onHide={() => setIsOpenClaim(false)}
        openComplete={() => {
          setIsOpenClaim(false);
          setIsOpenClaimSuccess(true);
        }}
      />
      <ClaimRewardsSuccess
        claimableAmount={userPoint}
        show={isOpenClaimSuccess}
        onHide={() => {
          setIsOpenClaimSuccess(false);
          window.location.reload();
        }}
      />
    </div>
  );
};

export default Rewards;
