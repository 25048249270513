import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useActiveWeb3React from '../../hooks/useActiveWeb3React';
import WalletConnector from 'components/auth/WalletConnector/WalletConnector';
import Popup from 'reactjs-popup';
import AggregateUserInfor from 'components/AggregateUserInfor/AggregateUserInfor';
import ErrorIcon from '@mui/icons-material/Error';
import talken_logo from '../../../src/talkclaim/talk.png';
import logo_google from '../../assets/icon/logo_google.png';
import { ReactComponent as LogoMetamask } from '../../assets/icon/metamask.svg';
import { ReactComponent as LogoWalletconnector } from '../../assets/icon/Wclogo.svg';
import { ReactComponent as LogoABC } from '../../assets/icon/abc.svg';
import { ReactComponent as IconABC } from '../../assets/icon/logo_abc.svg';
import { ReactComponent as Copy } from '../../assets/icon/copy.svg';
import CopyBlue from '../../assets/icon/copy_blue.png';
import sns_connect from '../../assets/svg/sns_connect.svg';
import sns_connect_no from '../../assets/svg/sns_connect_no.svg';
import { ReactComponent as ReferralProgram } from '../../assets/icon/question_profile.svg';
import { ReactComponent as DiscordCicrle } from '../../assets/svg/discord_cicrle.svg';
import { ReactComponent as XCicrle } from '../../assets/svg/x_cicrle.svg';
import { ReactComponent as Link } from '../../assets/icon/link.svg';
import { ReactComponent as Trash } from '../../assets/icon/trash.svg';
import { ReactComponent as TickXanh } from '../../assets/svg/tickxanh.svg';
import logo_apple from '../../assets/icon/logo_apple.png';
import activate_wallet from '../../assets/img/activate_wallet.png';
import logo_apple_black from '../../assets/icon/logo_apple_black.png';
import logo_facebook from '../../assets/icon/logo_facebook.png';
import { ethers } from 'ethers';
import { useDispatch, useSelector } from 'react-redux';
import { initUser, setUser } from '../../redux/slices/user';
import { dropsUsernameDuplicateCheck, updateUser, updateAddress, deleteAddress } from '../../services/services';
import { SUCCESS } from '../../config';
import makeBlockie from 'ethereum-blockies-base64';
import { useThemeMode } from 'components/common/AppStoreType';
import CSnackbar from '../../components/common/CSnackbar';
import { ReactComponent as EditAvatar } from '../../assets/icon/edit_avatar.svg';
import GoogleAnalytics from 'react-ga';
import splitAddress from 'utils/splitAddress';
import { useTranslation } from 'react-i18next';
import TranferOTPModal from 'components/modal/TranferOTPModal';
import TransferModal from 'components/modal/TransferModal';
import TransferSuccessModal from 'components/modal/TransferSuccessModal';
import LicenseAgreementABC from '../../components/activateWallet/LicenseAgreementABC';
import ExternalWallet from '../../components/activateWallet/ExternalWallet';
import { getAllBalances } from '../../utils/transactions';
import useCopyToClipBoard from 'hooks/useCopyToClipboard';
import ABCWalletCode from 'components/activateWallet/ABCWalletCode';
import { isMobile } from 'react-device-detect';
import { getActiveWallet } from '../../utils/getActiveWallet';
import ReferralProgramModal from 'components/modal/ReferralProgram';
import useScreenSize from 'components/common/useScreenSize';
import help_circle from '../../assets/svg/help_circle.svg';
import ReactTooltip from 'react-tooltip';

const overlayStyle = { background: 'rgba(0,0,0,0.8)' };
const closeOnDocumentClick = false;
const lockScroll = true;

const MyProfileNewDesign = () => {
  const user = useSelector((state: any) => state.user.user);
  const { copyToClipBoard, copyResult, setCopyResult } = useCopyToClipBoard();
  const { t, i18n } = useTranslation();
  const isLogin = user.uid !== '';
  const userEthAddr = user.abc_address?.toLowerCase();
  const userExtAddr = user.eth_address?.toLowerCase();
  const isEthAddress = ethers.utils.isAddress(userEthAddr);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [twitter, setTwitter] = useState('');
  const [instagram, setInstagram] = useState('');
  const [previewLogo, setPreviewLogo] = useState<any>('');
  const [logo, setLogo] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckName, setIsCheckName] = useState(false);
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [doAddWallet, setDoAddWallet] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    type: '',
    message: '',
  });
  const [authLogo, setAuthLogo] = useState(talken_logo);
  const [loginOpen, setLoginOpen] = useState(false);
  const [activateWallet, setActivateWallet] = useState(false);
  const [externalWallet, setExternalWallet] = useState(false);
  const [abcWalletCode, setABCWalletCode] = useState(false);
  const [signupOpen, setSignupOpen] = useState(false);
  const [isOpenTransfer, setIsOpenTransfer] = useState(false);
  const [isOpenTransferOTP, setIsOpenTransferOTP] = useState(false);
  const [isOpenTransferSucces, setIsOpenTransferSucces] = useState(false);
  const [editNameMode, setEditNameMode] = useState(false);
  const [editTwitterMode, setEditTwitterMode] = useState(false);
  const [openReferralProgram, setOpenReferralProgram] = useState(false);
  const { deactivate, account, library } = useActiveWeb3React();
  const myWallet = getActiveWallet();
  const navigate = useNavigate();
  const { isDarkMode } = useThemeMode();
  // const walletStatus = window.localStorage.getItem('walletStatus');
  const MAX_UPLOAD_FILE_SIZE = 2 * 1024 * 1024; // 2MB

  const [balances, setBalances] = useState([]);
  const abcUser = JSON.parse(window.localStorage.getItem('abcUser') as string);
  const loginWallet = localStorage.getItem('login');
  // const subWallet = localStorage.getItem('subWallet');
  const subWallet = user.sub_wallet; // Use data store in DB
  const isSns = window.localStorage.getItem('login') === 'sns';

  const [reload, setReload] = useState(false);
  const isProduction = process.env.REACT_APP_PHASE === 'production';
  const domain = isProduction ? 'https://apps.talken.io' : 'https://dev.apps.talken.io';

  const checkFileSize = (fileBlob: File) => {
    if (fileBlob) return fileBlob.size > MAX_UPLOAD_FILE_SIZE;
  };

  const handleClickLogout = async () => {
    GoogleAnalytics.event({
      category: 'UserActivity',
      action: 'Logout',
      label: 'UsernameBox Logout Button',
    });
    dispatch(initUser());
    deactivate();
    localStorage.removeItem('nftapiJwtToken');
    localStorage.removeItem('walletconnect');
    sessionStorage.removeItem('persist:root');
    localStorage.removeItem('scrollPosition');
    window.location.href = `${process.env.REACT_APP_API_SERVER}/auth/logout`;
  };

  const initAccountData = () => {
    if (user) {
      setName(user.name || '');
      if (user.email) setEmail(user.email);
      if (user.profile_image) setPreviewLogo(user.profile_image);
      if (user.twitter) setTwitter(user.twitter);
      // setInstagram(user.instagram);
      setIsCheckName(false);
      setIsDuplicateName(false);
      setIsCheckEmail(false);
      if (user.auth_type === 'GOOGLE') {
        setAuthLogo(logo_google);
      } else if (user.auth_type === 'FACEBOOK') {
        setAuthLogo(logo_facebook);
      } else if (user.auth_type === 'APPLE') {
        {
          isDarkMode ? setAuthLogo(logo_apple) : setAuthLogo(logo_apple_black);
        }
      }
    }
  };
  const encodeFileToBase64 = (fileBlob: File) => {
    const reader = new FileReader();

    reader.readAsDataURL(fileBlob);

    return new Promise<void>((resolve) => {
      reader.onload = () => {
        setPreviewLogo(reader.result);
        setLogo(fileBlob);
        resolve();
      };
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar({
      open: false,
      type: 'info',
      message: '',
    });
  };

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (checkFileSize(e.target.files[0])) {
        return alert('Exceed upload capacity. (2 MB)');
      }
      encodeFileToBase64(e.target.files[0]);
    }
  };

  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'name') setName(value);
    else if (name === 'email') setEmail(value);
    else if (name === 'twitter') setTwitter(value);
    else if (name === 'instagram') setInstagram(value);
    if (name === 'name') {
      const regex = /^[a-zA-Z0-9_-]*$/;
      const checkName = regex.test(value) && value.length < 16 && value.length > 3;
      setIsCheckName(!checkName);
    }
    if (name === 'email') {
      const regex = /^[a-zA-Z0-9\-_.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      // const regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
      const checkEmail = regex.test(value);
      setIsCheckEmail(!checkEmail);
    }
  };

  const handleOnBlur = async () => {
    if (name === user.name) return setIsDuplicateName(false);
    const res = await dropsUsernameDuplicateCheck(name);
    if (res.data.status !== SUCCESS) setIsDuplicateName(true);
    else setIsDuplicateName(false);
  };

  const handleDeleteAddressClick = async () => {
    if (loginWallet == 'metamask' || loginWallet == 'walletconnector') {
      alert('External wallet can be deleted after logging in with your SNS ID.');
      return;
    }
    setIsLoading(true);
    try {
      window.localStorage.removeItem('walletStatus');
      const data = {};
      const res = await deleteAddress(data);
      dispatch(setUser(res.data));
      deactivate();
      localStorage.removeItem('walletconnect');
    } catch (error: any) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  const handleClickSave = async (editTarget: string) => {
    setIsLoading(true);
    const formData = new FormData();
    const complete_message = 'Profile changed.';
    if (email) formData.append('email', email);
    if (name) formData.append('name', name);
    if (editTarget === 'all' || editTarget === 'twitter') {
      formData.append('twitter', twitter);
    }
    if (editTarget === 'all' || editTarget === 'instagram') {
      formData.append('instagram', instagram);
    }
    if (editTarget === 'all' || editTarget === 'profileImage') {
      if (logo) formData.append('profileImage', logo);
    }

    const res = await updateUser(user.uid, formData);
    if (res.status === 200) {
      console.log('update res:::', res.data);
      alert(complete_message);
      dispatch(setUser(res.data));
      // close();
    } else {
      setOpenSnackbar({
        open: true,
        type: 'error',
        message: 'Account update failed.',
      });
    }
    setIsLoading(false);
    if (editTarget === 'name') {
      setEditNameMode(false);
    } else if (editTarget === 'twitter') {
      setEditTwitterMode(false);
    }
    setLogo(undefined);
  };

  const closeLogin = () => {
    setLoginOpen(false);
  };
  const closeSignup = () => {
    setSignupOpen(false);
  };
  const closeActivateWallet = () => {
    setActivateWallet(false);
  };
  const handleAddWallet = async () => {
    setDoAddWallet(false);
    setLoginOpen(false);
    // setActivateWallet(true);
    setABCWalletCode(true);
  };
  const handleAddWalletOld = async () => {
    setDoAddWallet(true);
    setLoginOpen(true);
  };
  const handleAddExWallet = async () => {
    setExternalWallet(true);
  };
  useEffect(() => {
    const saveAddress = async () => {
      setIsLoading(true);
      try {
        const target_copy = Object.assign({}, library.provider);
        const isAbc = target_copy.isABC === true;
        const isKaikas = typeof target_copy._kaikas !== 'undefined';
        const chainId = await library.getSigner().getChainId();
        const message = `apps.talken.io wants you to sign in with your Ethereum account.
        Talken Drops Signature Request Type: Address verification`;
        const domain = { chainId }; // All properties on a domain are optional
        const types = { Mail: [{ name: 'contents', type: 'string' }] };
        const mail = { contents: message };
        const signature = await library
          .getSigner()
          ._signTypedData(domain, types, mail)
          .catch(() => deactivate());
        const msgParams = JSON.stringify({ domain, types, mail });
        if (!signature) return setIsLoading(false); // 서명 거부
        const subWallet = window.localStorage.getItem('subWallet');
        const data = { msgParams, signature, subWallet };
        const res = await updateAddress(data);
        dispatch(setUser(res.data));
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setDoAddWallet(false);
        setIsLoading(false);
      }
    };

    if (!account || !library?.connection || !doAddWallet) return;
    saveAddress();
  }, [library]);

  const handleClickEdit = (editTarget: string) => {
    if (editTarget === 'name') {
      setEditNameMode(!editNameMode);
    } else if (editTarget === 'twitter') {
      setEditTwitterMode(!editTwitterMode);
    }
  };

  const handleClickCancel = (editTarget: string) => {
    if (editTarget === 'name') {
      setEditNameMode(false);
    } else if (editTarget === 'twitter') {
      setEditTwitterMode(false);
    }
    initAccountData();
  };

  useEffect(() => {
    initAccountData();
    if (!userEthAddr) deactivate();
  }, [user]);
  useEffect(() => {
    localStorage.removeItem('walletconnect');
    let lang = window.localStorage.getItem('language');
    if (lang == 'null') {
      lang = 'en';
    }
    window.localStorage.setItem('language', lang);
    i18n.changeLanguage(lang);
  }, []);

  const fetchAllBalances = async () => {
    const balances = await getAllBalances(myWallet);
    setBalances(balances);
  };
  useEffect(() => {
    if (myWallet && myWallet !== '') fetchAllBalances();
  }, [account, myWallet]);
  useEffect(() => {
    setOpenSnackbar({
      open: copyResult,
      type: 'success',
      message: 'Copied!',
    });
  }, [copyResult]);
  const screenSize = useScreenSize();

  useEffect(() => {
    if (!isLogin) location.replace('/accounts?redirectUrl=/profile');
  }, []);

  const tooltipContent = `
    <ul class="color-92929f mt-0 p-l-16">
      <li>Base Talken Market fee is 2% (OpenSea 2.5%)</li>
      <li>For inviters, 0.5% of the sales amount will be converted to TALK and credited as reward points (ongoing)</li>
      <li>For buyers, 1.0% of the purchase amount will be converted to TALK and credited as reward points (0.5% ongoing + 0.5% event)</li>
      <li>For sellers, 1.0% of the seller's fee will be converted to TALK and credited as reward points (0.5% ongoing + 0.5% event)</li>
    </ul>
  `;

  return (
    <div className="my-profile-new-design min-height-content">
      {!isMobile && <AggregateUserInfor />}
      <div className="content-my-profile">
        <div className="title-my-profile mb-4">Profile</div>
        {isMobile && <AggregateUserInfor />}
        <div className="wrapper-content-profile">
          <div className="content-profile-left">
            <div className="title-profile-img">Profile Image</div>
            <div className="img-profile">
              <img
                id="avatar_upload"
                src={
                  previewLogo ||
                  (isEthAddress && userExtAddr
                    ? makeBlockie(userExtAddr)
                    : userEthAddr
                    ? makeBlockie(userEthAddr)
                    : userExtAddr
                    ? makeBlockie(userExtAddr)
                    : talken_logo)
                }
                alt=""
              />
              <input type="file" id="file-upload" accept="image/*" onChange={handleChangeImage} />
              <div className="icon-edit">
                <EditAvatar />
                <input type="file" id="file-upload" accept="image/*" onChange={handleChangeImage} />
              </div>
            </div>
            <div className="recommand-size">
              Recommanded size <br /> 400px X 400px
            </div>
            {logo && (
              <div className="upload-profile" onClick={() => handleClickSave('profileImage')}>
                Save
              </div>
            )}
          </div>
          <div className="content-profile-right">
            <div className="wrapper-nickname">
              <div className="title-nickname">Username</div>
              <div
                className={`wrapper-input-name ${
                  editNameMode && screenSize < 769 ? 'flex-column flex-center gap-10px' : ''
                }`}
              >
                {editNameMode ? (
                  <input
                    placeholder="Change Username"
                    className="input-change-nickname"
                    name="name"
                    value={name}
                    onChange={handleChangeInputValue}
                    onBlur={handleOnBlur}
                    disabled={!editNameMode}
                  />
                ) : (
                  <div className="nickname-div">{user.name}</div>
                )}
                {!editNameMode && (
                  <button onClick={() => handleClickEdit('name')} className="hover-opa-7 button button-edit">
                    Edit
                  </button>
                )}
                {screenSize <= 768 && (
                  <>
                    <div className="bottom-wrapper-nickname">
                      {editNameMode && (
                        <div className="content-proviso">4~15 characters, alphabet, number, underscore, period</div>
                      )}
                      <div className="d-flex justify-content-end">
                        {isCheckName ? (
                          <div className="duplicate-wrapper">
                            <ErrorIcon className="duplicate-info-icon" />
                            <span className="duplicate-info">Invalid username.</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {!isCheckName && isDuplicateName && (
                      <div className="duplicate-wrapper">
                        <ErrorIcon className="duplicate-info-icon" />
                        <span className="duplicate-info">That username is duplicated. Try another.</span>
                      </div>
                    )}
                  </>
                )}
                {editNameMode && (
                  <div className="wrapper-buttons">
                    <button
                      className={'hover-opa-7 button button-nickname btn-cancel'}
                      onClick={() => handleClickCancel('name')}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleClickSave('name')}
                      disabled={isCheckName || name === '' || isDuplicateName}
                      className="hover-opa-7 button button-nickname btn-save"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
              {screenSize > 768 && (
                <>
                  <div className="bottom-wrapper-nickname">
                    {editNameMode && (
                      <div className="content-proviso">4~15 characters, alphabet, number, underscore, period</div>
                    )}
                    <div className="d-flex justify-content-end">
                      {isCheckName ? (
                        <div className="duplicate-wrapper">
                          <ErrorIcon className="duplicate-info-icon" />
                          <span className="duplicate-info">Invalid username.</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {!isCheckName && isDuplicateName && (
                    <div className="duplicate-wrapper">
                      <ErrorIcon className="duplicate-info-icon" />
                      <span className="duplicate-info">That username is duplicated. Try another.</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="line-profile"></div>
            {isSns && (
              <>
                <div className="signed-in-social">
                  <div className="title-signed-in">Signed-in social account</div>
                  <div className="infor-signed-in">
                    <div className="bg-social-account flex-center">
                      <div className="img-social-account">
                        <img src={authLogo} alt="Google" />
                      </div>
                    </div>
                    <div className="address-account">{email}</div>
                  </div>
                </div>
                <div className="line-profile"></div>
              </>
            )}
            {/* start Linked SNS accounts */}
            {/* {!isProduction && (
              <>
                <div className="d-flex flex-column gap-8px">
                  <div className="fs-14 fs-xs-12 fw-7">Linked SNS accounts</div>
                  <div className="d-flex gap-8px flex-xs-column">
                    <div className="box-uid-referral">
                      <div className="align-items-center d-flex gap-6px">
                        <DiscordCicrle />
                        <div className="color-8d8d8d">Discord account</div>
                      </div>
                      <img src={sns_connect} className="hover-cusor-pointer hover-opa-7 w-20px" alt="" />
                    </div>
                    <div className="box-uid-referral">
                      <div className="align-items-center d-flex gap-6px">
                        <XCicrle />
                        <div className="color-8d8d8d">X account</div>
                      </div>
                      <img
                        src={sns_connect_no}
                        className="hover-cusor-pointer hover-opa-7 w-20px"
                        alt=""
                        onClick={() => {
                          alert('Coming Soon');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="line-profile"></div>
              </>
            )} */}
            {/* end Linked SNS accounts */}
            {/* start UID & Referral link */}
            {true && (
              <>
                <div className="d-flex flex-column gap-8px">
                  <div className="fs-14 fs-xs-12 fw-7">UID & Referral link</div>
                  <div className="d-flex gap-8px flex-xs-column">
                    <div className="box-uid-referral">
                      <div className={isSns ? 'fs-14' : 'fs-12'}>{user?.uid}</div>
                      <img
                        src={CopyBlue}
                        className="hover-cusor-pointer hover-opa-7 w-16px"
                        alt=""
                        onClick={() => copyToClipBoard(user?.uid)}
                      />
                    </div>
                    <div className={`${isSns ? 'fs-14' : 'fs-12'} box-uid-referral`}>
                      <div>{`https://${isProduction ? '' : 'dev.'}apps...ref=${user?.uid}`}</div>
                      <img
                        src={CopyBlue}
                        className="hover-cusor-pointer hover-opa-7 w-16px"
                        alt=""
                        onClick={() => copyToClipBoard(`${domain}/accounts?ref=${user?.uid}`)}
                      />
                    </div>
                  </div>
                  <div className="align-items-md-center align-items-start flex-start gap-6px">
                    <div className="fs-12 fw-5 dark:text-ffffffb2 w-xs-83">
                      Invite friends now to receive extra roulette bonuses and Talken Market trading rewards.
                    </div>
                    {/* <ReferralProgram
                      width={screenSize < 769 ? 16 : 12}
                      height={screenSize < 769 ? 16 : 12}
                      className="hover-cusor-pointer hover-opa-7"
                      onClick={() => setOpenReferralProgram(true)}
                    /> */}
                    <img
                      data-class="my-tooltip"
                      data-multiline={true}
                      className="button h-16px w-16px"
                      src={help_circle}
                      alt=""
                      // data-tip={`The Gas fee covers the source and destination blockchains transaction fees paid by Layer Zero. <br>\n Source Gas fee: "0.12345", <br>\n Destination Gas fee: $0.12345`}
                      data-tip={tooltipContent}
                      data-for="helpTooltip"
                      max-width="50"
                    />
                    <ReactTooltip id="helpTooltip" place="top" type="dark" effect="solid" html={true} />
                  </div>
                </div>
                <div className="line-profile"></div>
              </>
            )}
            {/* end UID & Referral link */}
            <div className="wrapper-wallet-address">
              <div className="wallet-address-title-button">
                <div className="title-wallet-address title-profile mb-1">{t('wallet-address')}</div>
              </div>
              {!isSns ? null : !userEthAddr || (userEthAddr && !abcUser?.twoFactorEnabled) ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div
                      className={`align-items-center d-flex fs-14 fw-5 ${
                        isMobile ? 'bg-abc-wallet-begin-mb' : 'row bg-abc-wallet-begin'
                      }`}
                    >
                      <div className={isMobile ? 'col-1' : 'col-1'}>
                        <LogoABC width={'38px'} height={'38px'} />
                      </div>
                      <div
                        className={isMobile ? 'col-11 p-l-26' : 'col-9 p-l-22 hover-cusor-pointer'}
                        onClick={() => copyToClipBoard(userEthAddr)}
                      >
                        {isMobile ? userEthAddr?.slice(0, 8) + '...' + userEthAddr?.slice(-6) : userEthAddr}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <button
                        className={`hover-opa-7 button button-profile bg-blue-primary h-50px w-140px w-xs-93px p-t-xs-12 p-b-xs-12 p-l-xs-24 p-r-xs-24 lh-normal ${
                          isMobile ? 'btn-act-w-b' : 'btn-act-w'
                        }`}
                        onClick={handleAddWallet}
                      >
                        Activate Wallet
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`${loginWallet === 'sns' ? 'bg-abc-wallet' : 'meta-connect'} align-items-center d-flex ${
                      isMobile ? '' : 'row'
                    }`}
                  >
                    <div className={isMobile ? 'col-1' : 'col-1'}>
                      <LogoABC width={'38px'} height={'38px'} />
                    </div>
                    <div className={isMobile ? 'col-8 p-l-16' : 'col-9'}>
                      {isMobile ? userEthAddr.slice(0, 8) + '...' + userEthAddr.slice(-6) : userEthAddr}
                      {/* {loginWallet === 'sns' && <TickXanh className="m-l-10" />} */}
                      {/*<div className="space-bw"></div>*/}
                    </div>
                    <div className={`text-end pe-3 ${isMobile ? 'col-3' : 'col-2'}`}>
                      <img
                        src={CopyBlue}
                        className="hover-cusor-pointer hover-opa-7 w-16px"
                        onClick={() => copyToClipBoard(userEthAddr)}
                        alt=""
                      />
                      {/*<button*/}
                      {/*  className="hover-opa-7 border-0 rounded-circle btn-circle m-r-10"*/}
                      {/*  onClick={() => setIsOpenTransfer(true)}*/}
                      {/*>*/}
                      {/*  <Link />*/}
                      {/*</button>*/}
                      {/*<div className="space-width"></div>*/}
                    </div>
                  </div>
                </>
              )}
              {isSns ? null : (
                <>
                  {myWallet ? (
                    <div
                      className={`${
                        loginWallet === 'metamask' || loginWallet === 'walletconnector' ? 'bg-abc-wallet' : ' '
                      } align-items-center d-flex ${isMobile ? '' : 'row'}`}
                    >
                      <div className={isMobile ? 'col-1' : 'col-1'}>
                        {loginWallet === 'metamask' && <LogoMetamask width={'38px'} height={'38px'} />}
                        {loginWallet === 'walletconnector' && <LogoWalletconnector width={'38px'} height={'38px'} />}
                      </div>
                      <div className={isMobile ? 'col-8 p-l-16' : 'col-9'}>
                        {isMobile ? myWallet?.slice(0, 8) + '...' + myWallet?.slice(-6) : myWallet}
                      </div>
                      <div
                        className={`text-end pe-3
                          ${isMobile ? 'col-3' : 'col-2'}`}
                      >
                        <img
                          src={CopyBlue}
                          className="hover-cusor-pointer hover-opa-7 w-16px"
                          onClick={() => copyToClipBoard(myWallet)}
                          alt=""
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              )}
              <div className="bottom-wallet-address">
                <div className="wallet-icon"></div>
                <div className="wallet-address"></div>
                {/*<div className="wallet-address-mobile">{userEthAddr ? splitAddress(userEthAddr) : '-'}</div>*/}
                {/* {userEthAddr && (
                  <button
                    disabled={isLoading}
                    onClick={handleDeleteAddressClick}
                    className="button button-profile"
                  >
                    Delete
                  </button>
                )} */}
                {/* <button className="button button-profile">View in Scan</button> */}
              </div>
            </div>
            <div className="line-profile"></div>
            <div className="wrapper-log-out">
              {screenSize > 768 && <div className="title-profile">Logout</div>}
              <button
                className="hover-opa-7 button-profile button"
                onClick={() => {
                  handleClickLogout();
                  navigate('/');
                }}
              >
                Logout
              </button>
            </div>
            {/* <div className="line-profile"></div> */}
            {/* <div className="wrapper-delete">
              <div className="title-profile">Delete</div>
              <button className="button-profile button button-delete">
                Delete
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <CSnackbar
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        handleClose={handleCloseSnackbar}
      />
      <Popup
        modal
        open={loginOpen}
        onOpen={closeSignup}
        onClose={closeLogin}
        {...{ overlayStyle, closeOnDocumentClick, lockScroll }}
      >
        {/* <LoginForm close={closeLogin} onConfirm={() => setSignupOpen(true)} /> */}
        <WalletConnector close={closeLogin} onConfirm={() => setSignupOpen(true)} />
      </Popup>
      {isOpenTransfer && (
        <TransferModal
          show={isOpenTransfer}
          onHide={() => setIsOpenTransfer(false)}
          typeModal={''}
          openWinningComplete={() => {
            setIsOpenTransfer(false);
            setIsOpenTransferOTP(true);
          }}
          data={undefined}
          setReload={setReload}
        ></TransferModal>
      )}
      {isOpenTransferOTP && (
        <TranferOTPModal
          show={isOpenTransferOTP}
          onHide={() => setIsOpenTransferOTP(false)}
          typeModal={''}
          openWinningComplete={() => {
            setIsOpenTransferOTP(false);
            setIsOpenTransferSucces(true);
          }}
        ></TranferOTPModal>
      )}
      {isOpenTransferSucces && (
        <TransferSuccessModal
          show={isOpenTransferSucces}
          onHide={() => setIsOpenTransferSucces(false)}
          typeModal={''}
          openWinningComplete={() => setIsOpenTransferSucces(false)}
          data={undefined}
        ></TransferSuccessModal>
      )}
      {/* <ActivateWallet
        show={activateWallet}
        onHide={() => setActivateWallet(false)}
        typeModal={undefined}
        openWinningComplete={() => setActivateWallet(false)}
      ></ActivateWallet> */}
      {abcWalletCode && (
        <ABCWalletCode
          show={abcWalletCode}
          onHide={() => setABCWalletCode(false)}
          typeModal={undefined}
          openWinningComplete={() => setABCWalletCode(false)}
        />
      )}
      {externalWallet && (
        <ExternalWallet
          show={externalWallet}
          onHide={() => setExternalWallet(false)}
          typeModal={undefined}
          openWinningComplete={() => setExternalWallet(false)}
        ></ExternalWallet>
      )}
      {openReferralProgram && (
        <ReferralProgramModal
          show={openReferralProgram}
          onHide={() => setOpenReferralProgram(false)}
        ></ReferralProgramModal>
      )}
      <CSnackbar
        open={openSnackbar.open}
        type={openSnackbar.type}
        message={openSnackbar.message}
        handleClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default MyProfileNewDesign;
